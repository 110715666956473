.ipod-box {
  position: absolute;
  z-index: 10;
  padding: 60px 40px;
  box-shadow: 6px 9px 17px 12px #cbcbcb;
  border-radius: 4px;
  background-color: #f4f4f4;
  margin-top: 170px;
  height: 686.52px;
  width: 457px;
  transition: margin-top 1.5s ease-in-out, transform 1.5s ease-in-out 1.5s,
    margin-bottom 3s ease-out 3.5s;
}

.ipod-box img {
  width: 377px;
  opacity: 0.7;
}

.knob {
  margin: 20px 0;
}

.shiny {
  color: white;
  border: none;
  overflow: hidden;
}

.shiny::after {
  content: "";
  position: absolute;
  top: calc(var(--y, 0) * 1px - 100px);
  left: calc(var(--x, 0) * 1px - 100px);
  width: 300px;
  height: 300px;
  background: radial-gradient(white, #3984ff00 100%);

  opacity: 0;
  transition: opacity 0.2s;
}

.shiny:hover::after {
  opacity: 0.4;
}

#ipod-container {
  opacity: 0;
}

.transition-fade {
  transition: 0.3s !important;
}

@media screen and (max-width: 576px) {
  #ipod-box {
    zoom: 0.8;
  }

  #ipod-container {
    width: 370px;
    height: 592px;
    border-radius: 30px;
  }
}
